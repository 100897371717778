.display {
	font-size: 12px;
}
.wrapper {
	display: flex;
	height: 100vh;
}

.appArea {
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
	flex: 1;
	> div {
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: flex-start;
	}
}
