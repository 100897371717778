.overlay {
	top: -16px;
	position: relative;
	display: flex;
	width: 100%;
	box-sizing: border-box;
	align-items: center;
	z-index: 3000;
	margin: 0;
}
.wrap {
	display: flex;
	width: 100vw;
	flex-direction: row;
	background: rgba(30, 30, 30, 0.9);
	padding: 16px;
	border-radius: 4px;
	font-size: 12px;
	margin-bottom: 16px;
}
.button {
	display: flex;
	align-items: center;
}
.mainText {
	font-size: 13px;
	overflow: hidden;
	padding: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 8px;
	flex: 1;
}

.cyberdevTeam {
	color: lightgreen;
	& > span {
		display: inline-block;
		opacity: 0;
	}
}

.title {
	font-size: 24px;
}
