.wrap {
	position: fixed;
	bottom: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
	background: linear-gradient(
		to bottom,
		rgb(25, 118, 210) 0%,
		rgba(25, 118, 210, 0.32) 5%,
		rgba(25, 118, 210, 0.32) 100%
	);
	height: 60px;
}
.logoText {
	font-family: sans-serif;
	font-weight: bolder;
	font-size: 32px;
	border: 6px solid white;
	padding: 8px;
	margin-top: 32px;
	margin-bottom: 16px;
}

.mainMsgText {
	font-family: sans-serif;
	font-weight: bolder;
	font-size: 12px;

	> * {
		margin-right: 32px;
	}
}
.markdown {
	* {
		white-space: pre-wrap;
	}
}
.cd {
	font-size: 12px;
	color: #ccc;
}
