@keyframes logo-appear {
	0% {
		opacity: 0;
		translate: (90px);
	}

	100% {
		opacity: 1;
		translate: (0px);
	}
}
.svgWrap {
	overflow: hidden;
	width: 90px;
	position: relative;
}

.svg {
	width: 90px;
	height: 90px;
	animation: logo-appear 0.6s cubic-bezier(0.09, 0.51, 0.32, 1.3) 0.2s 1
		normal backwards;
}

.logoWrap {
	padding: 16px 4px;
	display: flex;
	align-items: center;

	span {
		margin-right: 8px;
	}
	svg : {
	}
}

.burnLine {
	margin-right: 16px;
	width: 1px;
	height: 100px;
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0) 0%,
		rgba(255, 152, 0, 1) 25%,
		rgba(255, 152, 0, 1) 75%,
		rgba(0, 0, 0, 0) 100%
	);
}

.toolbarText {
	display: flex;
	font-weight: bolder;
	flex-direction: column;
	> span {
		color: #dddddd;
		font-size: 14px;
		&:first-child {
			font-size: 24px;
			color: #fff;
		}
	}
}

@keyframes flashIn {
	from {
		color: rgba(0, 0, 0, 0);
	}
	to {
		color: rgba(255, 87, 34, 1);
	}
}

.flash {
	* {
		fill: orange;
	}
	animation: flashIn 0.5s linear 20 alternate;
}
