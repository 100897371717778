.wrap {
	display: flex;
	flex-direction: column;
	width: 100vw;
	padding: 16px;
	box-sizing: border-box;
	justify-content: center;
	align-items: center;
	margin-bottom: 84px;
}

.title {
	font-size: 22px;
	margin-bottom: 8px;
}
.island {
	display: flex;
	flex-direction: column;
	width: 320px;
}

.textFields {
	display: flex;
	background: #445;
}
.registerText {
	font-size: 16px;
	max-width: 480px;
	margin-bottom: 32px;
}
