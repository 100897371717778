.buttonIsland {
	margin: 8px 0;
	text-align: right;
	padding-right: 16px;
	button {
		margin-bottom: 8px;
	}
}
.formTitle {
	background: linear-gradient(
		to bottom,
		rgb(25, 118, 210) 0%,
		rgba(25, 118, 210, 0.32) 5%,
		rgba(25, 118, 210, 0.32) 95%,
		rgba(25, 118, 210, 1) 100%
	);
}
