.wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
	flex: 0 0 auto;
	background: linear-gradient(
		to bottom,
		rgba(25, 118, 210, 0.32) 0%,
		rgba(25, 118, 210, 0.32) 98%,
		rgba(25, 118, 210, 1) 100%
	);
	padding: 16px 0px 24px 0px;
	> * {
		margin: 0 16px;
	}
}
.logoText {
	font-family: sans-serif;
	font-weight: bolder;
	font-size: 32px;
	border: 6px solid white;
	padding: 8px;
	margin-top: 32px;
	margin-bottom: 16px;
}

.mainMsgText {
	font-family: sans-serif;
	font-weight: bolder;

	font-size: 48px;
	@media screen and (max-width: 900px) {
		font-size: 16px;
	}

	padding: 8px;
	margin-bottom: 16px;
	> * {
		margin-right: 32px;
	}
}
.noAccountMsg {
	font-size: 18px;
	margin: 48px 0 16px;
	font-family: sans-serif;
	font-weight: bolder;
}
.smallMessage {
	max-width: 640px;
}

.logoWrap {
	display: flex;
	align-items: center;
}

.toolbarText {
	display: flex;
	font-weight: bolder;
	flex-direction: column;
	> span {
		font-size: 24px;
	}
}
.svgWrap {
	border-radius: 50%;
	background: white;
	width: 80px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 32px;
	svg {
		width: 64px;
		height: 64px;
	}
}
