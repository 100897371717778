.wrap {
	display: flex;
	flex-direction: column;
	width: 100vw;
	padding: 16px;
	box-sizing: border-box;
	align-items: center;
	margin-top: 64px;
	margin-bottom: 84px;
}

.title {
	font-size: 22px;
	margin-bottom: 8px;
	position: absolute;
	top: -22px;
	left: 16px;
	padding: 8px 16px 8px;
	background: linear-gradient(
		to bottom,
		rgb(25, 118, 210) 0%,
		rgba(25, 118, 210, 0.32) 5%,
		rgba(25, 118, 210, 0.32) 95%,
		rgba(25, 118, 210, 1) 100%
	);
	display: inline-block;
	border-radius: 4px 4px 0 0;
}
.island {
	display: flex;
	flex-direction: column;
	width: 320px;
}

.textFields {
	position: relative;
	padding: 42px 8px 48px;
	display: flex;
	flex-direction: column;
	background: #44474747;
}
.link {
	color: orange;
}
.alignSelf {
	align-self: flex-end;
}

.noAccountMsg {
	font-size: 18px;
	margin: 128px 0 32px;
	font-family: sans-serif;
	font-weight: bolder;
}
.loginText {
	max-width: 360px;
	font-size: 14px;
	margin-bottom: 48px;
	color: #ddd;
	text-align: center;
}
.progressWrap {
	margin-top: 48px;
	display: flex;
	justify-content: center;
}
.registrationUnderLogin {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 4px;
}
