.App {
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-content {
	background-color: #333;
	display: flex;
	flex-direction: column;
	//font-size: calc(10px + 2vmin);

	flex: 1 0 auto;
	color: white;
	overflow: hidden;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(-360deg);
	}
}
